<template>
    <div class="home">
        <div class="homeComAbout">
<!--            <h1 class="homeTitle center">-->
<!--                <img style="width: 60px;height: 60px;" src="@/assets/logo5.png" alt="">-->
<!--                贵州浩佑暖通制冷设备有限公司-->
<!--            </h1>-->
            <div class="banner-img">
                <img src="@/assets/content.png" alt="" style="width: 430px;height: 400px;margin-left: 100px;float: left;">
                <div style="float: left;width: 780px;margin-left: 30px;font-size: 16px;">
                  贵州名辉企业管理有限公司成立于2023年06月16日，注册地位于贵州省遵义市红花岗区忠庄街道吾悦广场步行街SY3-4-111号，法定代表人为陈清园。经营范围包括法律、法规、国务院决定规定禁止的不得经营；法律、法规、国务院决定规定应当许可（审批）的，经审批机关批准后凭许可（审批）文件经营；法律、法规、国务院决定规定无需许可（审批）的，市场主体自主选择经营。（一般项目：企业管理；运输货物打包服务；粮油仓储服务；普通货物仓储服务（不含危险化学品等需许可审批的项目）；软件开发；小微型客车租赁经营服务；汽车销售；供应链管理服务；日用百货销售；农副产品销售；信息咨询服务（不含许可类信息咨询服务）；汽车拖车、求援、清障服务；机动车修理和维护；凭总公司授权开展经营活动（除许可业务外，可自主依法经营法律法规非禁止或限制的项目）许可项目：道路货物运输（不含危险货物）；城市配送运输服务（不含危险货物）（依法须经批准的项目，经相关部门批准后方可开展经营活动））
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
	name: 'homePage',
	components: {
	}
}
</script>

<style lang="scss" scoped="scoped">
  .home{
    background:#fff;
  }
  .homeComAbout{
    .homeTitle{
      font-weight: 700;
      color: #303133;
      font-size: 2rem;
    }
    .titleIntroduce{
      color: #606266;
      font-size:1.1rem ;
      padding-bottom:14px;
    }
    .center{
      text-align: center;
    }
    .banner-img{
      width: 100%;
      height: 640px;
      >img{
        width: 1140px;
        height: 100%;
      }
    }
  }
</style>
